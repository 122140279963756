function onLoad(loadFunction: () => void) {
  if (/complete|interactive|loaded/.test(document.readyState)) {
    // In case the document has finished parsing, document's readyState will
    // be one of "complete", "interactive" or (non-standard) "loaded".
    loadFunction();
  } else {
    // The document is not ready yet, so wait for the DOMContentLoaded event
    document.addEventListener(
      'DOMContentLoaded',
      () => {
        loadFunction();
      },
      false
    );
  }
}

export default onLoad;
